/**
 * Created by apop on 06/09/2018.
 */
angular
    .module('annexaApp')
    .component('annexaGiaChangeNameActivity', {
        templateUrl: './components/tram/annexa-gia/annexa-gia-change-name-activity.html',
        controller: ['Language', 'TramNewFactory','$rootScope', 'GlobalDataFactory', '$filter', 'globalModals', 'AnnexaFormlyFactory', 'DialogsFactory', 'AnnexaPermissionsFactory', 'apiAdmin', 'RestService', '$scope', 'HelperService', 'TerritoryFactory', function (Language, TramNewFactory, $rootScope, GlobalDataFactory, $filter, globalModals, AnnexaFormlyFactory, DialogsFactory, AnnexaPermissionsFactory, apiAdmin, RestService, $scope, HelperService, TerritoryFactory) {
        	var vm = this;
            vm.languageColumn = "language1";
            vm.activity = {};
            vm.activityAux = {};
            vm.showActivity = false;
            
            vm.exacts = [
            	{id:'0' , name: $filter('translate')('global.tram.literals.gia.exact0')},
            	{id:'1' , name: $filter('translate')('global.tram.literals.gia.exact1')}
            ];
            
            vm.estats = [
            	{id:'0' , name: $filter('translate')('global.tram.literals.gia.estat0')},
            	{id:'1' , name: $filter('translate')('global.tram.literals.gia.estat1')},
            	{id:'2' , name: $filter('translate')('global.tram.literals.gia.estat2')}
            ];
            
            vm.updateActivity = function(val, prop){
            	if(vm.activity && vm.activity.id && vm.dossierTransaction && vm.dossierTransaction.id){
            		vm.activity[prop] = val;
            		TramNewFactory.updateChangeNameGia(vm.dossierTransaction.id, vm.activity).then(function(data){
            			vm.activityAux[prop] = vm.activity[prop];
            		}).catch(function(error){
           				vm.activity[prop] = vm.activityAux[prop];
            			DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.updateError'));	
					});
            	}else{
            		DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.activityNotExist'));
            	}
			}
            
			var submitModalThird = function(selected, modal){
				if(selected && selected.length > 0 && vm.activity && vm.activity.id && vm.dossierTransaction && vm.dossierTransaction.id){
	              	  if(selected[0].id){
	              		  var modifyActivityThird = function(from, to){
	              			to.nomRs = from.nomRs; 
	              			to.nifRs = from.nifRs;
	              			to.munRs = from.munRs; 
	              			to.cpRs = from.cpRs;
	              			to.tipusViaRs = from.tipusViaRs; 
	              			to.nomViaRs = from.nomViaRs;
	              			to.numViaRs = from.numViaRs; 
	              			to.escRs = from.escRs; 
	              			to.pisRs = from.pisRs;
	              			to.portaRs = from.portaRs; 
	              			to.paisRs = from.paisRs;
	              			to.emailRs = from.emailRs;
	              			to.telRs = from.telRs; 
	              			to.mobRs = from.mobRs;
	              			to.nomRl = from.nomRl; 
	              			to.nifRl = from.nifRl;
	              			to.haveRl = from.haveRl;
	              			to.munRl = from.munRl; 
	              			to.cpRl = from.cpRl;
	              			to.tipusViaRl = from.tipusViaRl; 
	              			to.nomViaRl = from.nomViaRl;
	              			to.numViaRl = from.numViaRl; 
	              			to.escRl = from.escRl; 
	              			to.pisRl = from.pisRl;
	              			to.portaRl = from.portaRl; 
	              			to.paisRl = from.paisRl;
	              			to.munEmpl = from.munEmpl; 
	              			to.emailRl = from.emailRl;
	              			to.telRl = from.telRl; 
	              			to.mobRl = from.mobRl;
	              		  }
	              		  TramNewFactory.updateThirdActivityChangeName(vm.dossierTransaction.id, vm.activity.id, selected[0].id).then(function(data){
	              			  	modifyActivityThird(data, vm.activity);
	              				modifyActivityThird(data, vm.activityAux);
	                			modal.close();
	                		}).catch(function(error){
	                			modifyActivityThird(vm.activityAux, vm.activity);
	                			DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.updateError'));	
	    					});         	
	             	   	  }
	                }
			}
			
			var submitModalActivity = function(selected, modal){
                if(selected && selected.length > 0 && vm.activity && vm.activity.id && vm.dossierTransaction && vm.dossierTransaction.id){
              	  if(selected[0].id){
              		vm.activity.idAc = selected[0].idAc; 
              		TramNewFactory.updateChangeNameGia(vm.dossierTransaction.id, vm.activity).then(function(data){
            			vm.activityAux.idAc = vm.activity.idAc; 
            			modal.close();
            		}).catch(function(error){
            			vm.activity.idAc = vm.activityAux.idAc; 
            			DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.updateError'));	
					});         	
         	   	  }
                }
              }
              
            $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
            	if(args && args.id){
	                if(args.id === 'modalGIAGetThird') {
	                	submitModalThird(args.selected, args.modal);
	                }else if(args.id === 'modalGIAActivity') {
  	                	submitModalActivity(args.selected, args.modal);
  	  	            }
            	}
            });
            
            vm.openModalGetThird = function(){
            	var thirdTypeColumn = new IconFilterColumn($rootScope, 'filterData', 'thirdType', [
                    new IconSearchOption('all', '', 'fa-minus fa-stack-1x', 'fa-minus', 'global.literals.all'),
                    new IconSearchOption('PHISICAL','PHISICAL', 'fa-user fa-lg ico-third', 'fa-user fa-fw', 'global.thirds.literals.phisical'),
                    new IconSearchOption('LEGAL','LEGAL', 'fa-bank fa-lg ico-third', 'fa-bank fa-fw', 'global.thirds.literals.legal'),
                    new IconSearchOption('PERSONWITHOUTLEGALPERSONALITY','PERSONWITHOUTLEGALPERSONALITY', 'fa-puzzle-piece fa-lg ico-third', 'fa-puzzle-piece fa-fw', 'global.thirds.literals.personwithoutlegalpersonality')
                ], 'tableThirds');
            	
            	var streetTypes = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.streetTypes));
				var countries = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.countries));
                var advancedSearchModal = {
                 title: 'global.literals.advanced_search_receivers',
                 size: 'modal-lg',
                 filter: [
                	{ id: 'identificationDocument', type: 'text', order: 0, label: 'global.literals.identifier', callAux: true},
 					{ id: 'name', type: 'text', order: 1, label: 'global.literals.name', callAux: true},
 					{ id: 'identificationReference', type: 'text', order: 2, label: 'global.thirds.literals.identificationReference', callAux: true},
 					{ id: 'city', type: 'text', order: 4, label: 'global.literals.city', callAux: true, userChoosen: true},
 					{ id: 'mail', type: 'text', order: 5, label: 'global.profile.email', callAux: true, userChoosen: true},
 					{ id: 'phone', type: 'text', order: 6, label: 'global.profile.phone', callAux: true, userChoosen: true},
 					{ id: 'identificationDocumentType', type: 'select', order: 7, label: 'global.thirds.literals.identificationdocumenttype', dataType: 'LOCAL', data: vm.thirdIdentificationDocumentTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, userChoosen: true},
 					{ id: 'thirdType', type: 'select', order: 8, label: 'global.literals.type', dataType: 'LOCAL', data: vm.thirdTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, userChoosen: true},
 					{ id: 'dataThird', type: 'dateRange', order: 9, label: 'global.thirds.literals.dateCreated', callAux: true, userChoosen: true }
                 ],
                 tableDefinition: {
                     id: 'tableAdvancedSearchThird',
                     origin: 'tram',
                     objectType: 'DossierThird',
                     sortName: 'third.completeName',
                     sort: [[3, 'asc']],
                     columns: [
                    	 { id: 'third.thirdType', width: '150px', title: $filter('translate')('global.literals.type'), render:thirdTypeColumn.getRender},
                         { id: 'third.identificationDocument', width: '300px', title:$filter('translate')('global.literals.identifier') },
                         { id: 'third.completeName', width: '300px', title: $filter('translate')('global.thirds.literals.datatable.col2')}
                     ],
                     callAuxToAdd: {callFrom:"GIA"},
                     callToAdd: {dossier: {id:vm.dossierTransaction.dossierId}, interested:true}
                 },
                 multiple: false
             }
             AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalGIAGetThird', advancedSearchModal);
            }
            
            vm.openModalGetActivity = function(){
              	if(vm.dossierTransaction && vm.dossierTransaction.id){
              		var getActivity = function(pag, size){
              			var call = {}
              			if(this.filterCallAux){
              				if(this.filterCallAux.idAc){
              					call.idAc = this.filterCallAux.idAc
              				}
              				if(this.filterCallAux.refCadastral){
              					call.refCadastral = this.filterCallAux.refCadastral
              				}
              				if(this.filterCallAux.numExp){
              					call.numExp = this.filterCallAux.numExp
              				}
              				if(this.filterCallAux.nomRs){
              					call.nomRs = this.filterCallAux.nomRs
              				}
              				if(this.filterCallAux.nifRs){
              					call.nifRs = this.filterCallAux.nifRs
              				}
              				if(this.filterCallAux.nomCom){
              					call.nomCom = this.filterCallAux.nomCom
              				}
              				if(this.filterCallAux.activitatPpal){
              					call.activitatPpal = this.filterCallAux.activitatPpal
              				}
              				if(this.filterCallAux.activitatAltr){
              					call.activitatAltr = this.filterCallAux.activitatAltr
              				}
              				if(this.filterCallAux.idEstablGia){
              					call.idEstablGia = this.filterCallAux.idEstablGia
              				}
              				if(this.filterCallAux.idEstablGencat){
              					call.idEstablGencat = this.filterCallAux.idEstablGencat
              				}
              				if(this.filterCallAux.tipusViaEmpl){
              					call.tipusViaEmpl = this.filterCallAux.tipusViaEmpl
              				}
              				if(this.filterCallAux.nomViaEmpl){
              					call.nomViaEmpl = this.filterCallAux.nomViaEmpl
              				}
              				if(this.filterCallAux.numViaEmpl){
              					call.numViaEmpl = this.filterCallAux.numViaEmpl
              				}
              				if(this.filterCallAux.barriEmpl){
              					call.barriEmpl = this.filterCallAux.barriEmpl
              				}
              				if(this.filterCallAux.exact && this.filterCallAux.exact.id){
              					call.exact = this.filterCallAux.exact.id
              				}
              				if(this.filterCallAux.estat && this.filterCallAux.estat.id){
              					call.estat = this.filterCallAux.estat.id
              				}
              			}
              			return TramNewFactory.searchActivities(vm.dossierTransaction.id, call, pag, size);
              		}
              		var advancedSearchModal = {
              				title: 'global.tram.literals.gia.emplacament.classif',
              				size: 'modal-lg',
              				filter: [
              					{ id: 'idAc', type: 'text', order: 0, label: 'global.tram.literals.gia.activity', callAux: true },
              					{ id: 'refCadastral', type: 'text', order: 1, label: 'global.tram.literals.gia.emplacament.refCadastral', callAux: true },
              					{ id: 'numExp', type: 'text', order: 2, label: 'global.literals.dossierNumber', callAux: true},
              					{ id: 'nomRs', type: 'text', order: 3, label: 'global.tram.literals.gia.entitatRaoSocial', callAux: true },
              					{ id: 'nifRs', type: 'text', order: 4, label: 'global.tram.literals.gia.address.nif', callAux: true },
              					{ id: 'nomCom', type: 'text', order: 5, label: 'global.tram.literals.gia.emplacament.nomCom', callAux: true},
              					{ id: 'activitatPpal', type: 'text', order: 6, label: 'global.tram.literals.gia.emplacament.activitatPpal', callAux: true},
              					{ id: 'activitatAltr', type: 'text', order: 7, label: 'global.tram.literals.gia.emplacament.activitatAltr', callAux: true, userChoosen: true },
              					{ id: 'idEstablGia', type: 'text', order: 8, label: 'global.tram.literals.gia.emplacament.idEstGia', callAux: true, userChoosen: true},
              					{ id: 'idEstablGencat', type: 'text', order: 9, label: 'global.tram.literals.gia.emplacament.idEstGen', callAux: true, userChoosen: true },
              					{ id: 'tipusViaEmpl', type: 'text', order: 10, label: 'global.tram.literals.gia.address.tipusVia', callAux: true, userChoosen: true },
              					{ id: 'nomViaEmpl', type: 'text', order: 11, label: 'global.tram.literals.gia.address.nomVia', callAux: true, userChoosen: true},
              					{ id: 'numViaEmpl', type: 'text', order: 12, label: 'global.tram.literals.gia.address.num', callAux: true, userChoosen: true },
              					{ id: 'barriEmpl', type: 'text', order: 13, label: 'global.tram.literals.gia.address.barri', callAux: true, userChoosen: true },
              					{ id: 'exact', type: 'select', order: 14, label: 'global.tram.literals.gia.exact', dataType: 'LOCAL', data: vm.exacts, addAll: true, nameProperty: 'name', callAux: true, userChoosen: true},
              					{ id: 'estat', type: 'select', order: 15, label: 'global.tram.literals.gia.estat', dataType: 'LOCAL', data: vm.estats, addAll: true, nameProperty: 'name', callAux: true, userChoosen: true}
          					],
          					tableDefinition: {
          						id: 'tableAdvancedSearchActivity',
          						objectsPaginated:getActivity,
          						sortName: 'idAc',
          						sort: [[0, 'asc']],
          						columns: [
          							{ id: 'idAc', title: $filter('translate')('global.tram.literals.gia.activity'), sortable: false },
          							{ id: 'nomRs', title: $filter('translate')('global.tram.literals.gia.entitatRaoSocial'), sortable: false },
          							{ id: 'nomCom', title: $filter('translate')('global.tram.literals.gia.emplacament.nomCom'), sortable: false },
          							{ id: 'activitatPpal', title: $filter('translate')('global.tram.literals.gia.emplacament.activitatPpal'), sortable: false }
      							],
      							displayAll:true,
      							callAuxToAdd: { }
          					},
          					multiple: false
              		}
              		AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalGIAActivity', advancedSearchModal);
              	}else{
              		DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.activityNotExist'));
              	}
              }
              
              vm.emptyFunction = function(val, prop){
              }
              
            vm.changeNameGia = function(){
            	DialogsFactory.confirm('global.tram.literals.gia.activities.CHANGE_NAME', 'global.tram.literals.gia.changeNameActivity').then(function (data) {
            		if(vm.activity && vm.activity.id && vm.dossierTransaction && vm.dossierTransaction.id){
            			vm.showActivity = false;
            			TramNewFactory.executeChangeNameGia(vm.dossierTransaction.id).then(function(data){
            				if(data.errorCodi === '00' || !data.errorCodi){
            					vm.activity.executed = true;
            					vm.activityAux.executed = true;
            					DialogsFactory.notify($filter('translate')('global.tram.literals.gia.executedOk'));
            				}else{
            					var error = ((data.errorCodi)?data.errorCodi+((data.errorDescripcio)?" - ":''):'');
            					error = error + ((data.errorDescripcio)?data.errorDescripcio:'');
            					if(!error || error === ''){
            						error = $filter('translate')('global.tram.literals.gia.errors.updateError');
            					}
            					DialogsFactory.error(error);	
            				}
            				vm.showActivity = true;
                		}).catch(function(error){
                			if(error && error.message && error.message.toUpperCase().includes("required".toUpperCase())){
                				DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.changeNameRequired'));
                			}else{
                				DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.updateError'));	
                			}
                			vm.showActivity = true;
    					});
            		}else{
                		DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.activityNotExist'));
                	}
               }).catch(function (data) {
    	               //Empty
    	       });
            }
			this.$onInit = function () {
				vm.languageColumn = Language.getActiveColumn();
				if(vm.dossierTransaction && vm.dossierTransaction.id){
					TramNewFactory.getChangeNameGia(vm.dossierTransaction.id).then(function(data){
						vm.activity = JSOG.decode(data);
						vm.activityAux = angular.copy(vm.activity);
						vm.showActivity = true;
					}).catch(function(error){
						vm.showActivity = false;			
					});
				}else{
					vm.showActivity = false;
				}
			}
        }],
        bindings: {
            dossierTransaction: '=',
            canUpdate: '='
        }
    })