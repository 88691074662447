/**
 * Created by apop on 06/09/2018.
 */
angular
    .module('annexaApp')
    .component('annexaGiaRemoveActivity', {
        templateUrl: './components/tram/annexa-gia/annexa-gia-remove-activity.html',
        controller: ['Language', 'TramNewFactory','$rootScope', 'GlobalDataFactory', '$filter', 'globalModals', 'AnnexaFormlyFactory', 'DialogsFactory', 'AnnexaPermissionsFactory', 'apiAdmin', 'RestService', '$scope', function (Language, TramNewFactory, $rootScope, GlobalDataFactory, $filter, globalModals, AnnexaFormlyFactory, DialogsFactory, AnnexaPermissionsFactory, apiAdmin, RestService, $scope) {
        	var vm = this;
            vm.languageColumn = "language1";
            vm.activity = {};
            vm.activityAux = {};
            vm.showActivity = false;
            
            vm.exacts = [
            	{id:'0' , name: $filter('translate')('global.tram.literals.gia.exact0')},
            	{id:'1' , name: $filter('translate')('global.tram.literals.gia.exact1')}
            ];
            
            vm.estats = [
            	{id:'0' , name: $filter('translate')('global.tram.literals.gia.estat0')},
            	{id:'1' , name: $filter('translate')('global.tram.literals.gia.estat1')},
            	{id:'2' , name: $filter('translate')('global.tram.literals.gia.estat2')}
            ];
            
            vm.updateActivity = function(val, prop){
            	if(vm.activity && vm.activity.id && vm.dossierTransaction && vm.dossierTransaction.id){
            		vm.activity[prop] = val;
            		TramNewFactory.updateRemoveActivity(vm.dossierTransaction.id, vm.activity).then(function(data){
            			vm.activityAux[prop] = vm.activity[prop];
            		}).catch(function(error){
           				vm.activity[prop] = vm.activityAux[prop];
            			DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.updateError'));	
					});
            	}else{
            		DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.activityNotExist'));
            	}
			}
            var submitModalActivity = function(selected, modal){
              if(selected && selected.length > 0 && vm.activity && vm.activity.id && vm.dossierTransaction && vm.dossierTransaction.id){
            	  if(selected[0].id){
            		vm.activity.idAc = selected[0].idAc; 
            		vm.activity.nomRs = selected[0].nomRs;
            		vm.activity.activitatPpal = selected[0].activitatPpal;
            		TramNewFactory.updateRemoveActivity(vm.dossierTransaction.id, vm.activity).then(function(data){
              			vm.activityAux.idAc = vm.activity.idAc; 
              			vm.activityAux.nomRs = vm.activity.nomRs;
              			vm.activityAux.activitatPpal = vm.activity.activitatPpal;
              			modal.close();
              		}).catch(function(error){
              			vm.activity.idAc = vm.activityAux.idAc; 
              			vm.activity.nomRs = vm.activityAux.nomRs;
              			vm.activity.activitatPpal = vm.activityAux.activitatPpal;
              			DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.updateError'));	
  					});         	
           	   	  }
              }
            }
            
            $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
            	if(args && args.id){
	                if(args.id === 'modalGIAActivity') {
	                	submitModalActivity(args.selected, args.modal);
	                }
            	}
            });
            
            vm.openModalGetActivity = function(){
            	if(vm.dossierTransaction && vm.dossierTransaction.id){
            		var getActivity = function(pag, size){
            			var call = {}
            			if(this.filterCallAux){
            				if(this.filterCallAux.idAc){
            					call.idAc = this.filterCallAux.idAc
            				}
            				if(this.filterCallAux.refCadastral){
            					call.refCadastral = this.filterCallAux.refCadastral
            				}
            				if(this.filterCallAux.numExp){
            					call.numExp = this.filterCallAux.numExp
            				}
            				if(this.filterCallAux.nomRs){
            					call.nomRs = this.filterCallAux.nomRs
            				}
            				if(this.filterCallAux.nifRs){
            					call.nifRs = this.filterCallAux.nifRs
            				}
            				if(this.filterCallAux.nomCom){
            					call.nomCom = this.filterCallAux.nomCom
            				}
            				if(this.filterCallAux.activitatPpal){
            					call.activitatPpal = this.filterCallAux.activitatPpal
            				}
            				if(this.filterCallAux.activitatAltr){
            					call.activitatAltr = this.filterCallAux.activitatAltr
            				}
            				if(this.filterCallAux.idEstablGia){
            					call.idEstablGia = this.filterCallAux.idEstablGia
            				}
            				if(this.filterCallAux.idEstablGencat){
            					call.idEstablGencat = this.filterCallAux.idEstablGencat
            				}
            				if(this.filterCallAux.tipusViaEmpl){
            					call.tipusViaEmpl = this.filterCallAux.tipusViaEmpl
            				}
            				if(this.filterCallAux.nomViaEmpl){
            					call.nomViaEmpl = this.filterCallAux.nomViaEmpl
            				}
            				if(this.filterCallAux.numViaEmpl){
            					call.numViaEmpl = this.filterCallAux.numViaEmpl
            				}
            				if(this.filterCallAux.barriEmpl){
            					call.barriEmpl = this.filterCallAux.barriEmpl
            				}
            				if(this.filterCallAux.exact && this.filterCallAux.exact.id){
            					call.exact = this.filterCallAux.exact.id
            				}
            				if(this.filterCallAux.estat && this.filterCallAux.estat.id){
            					call.estat = this.filterCallAux.estat.id
            				}
            			}
            			return TramNewFactory.searchActivities(vm.dossierTransaction.id, call, pag, size);
            		}
            		var advancedSearchModal = {
            				title: 'global.tram.literals.gia.emplacament.classif',
            				size: 'modal-lg',
            				filter: [
            					{ id: 'idAc', type: 'text', order: 0, label: 'global.tram.literals.gia.activity', callAux: true },
            					{ id: 'refCadastral', type: 'text', order: 1, label: 'global.tram.literals.gia.emplacament.refCadastral', callAux: true },
            					{ id: 'numExp', type: 'text', order: 2, label: 'global.literals.dossierNumber', callAux: true},
            					{ id: 'nomRs', type: 'text', order: 3, label: 'global.tram.literals.gia.entitatRaoSocial', callAux: true },
            					{ id: 'nifRs', type: 'text', order: 4, label: 'global.tram.literals.gia.address.nif', callAux: true },
            					{ id: 'nomCom', type: 'text', order: 5, label: 'global.tram.literals.gia.emplacament.nomCom', callAux: true},
            					{ id: 'activitatPpal', type: 'text', order: 6, label: 'global.tram.literals.gia.emplacament.activitatPpal', callAux: true},
            					{ id: 'activitatAltr', type: 'text', order: 7, label: 'global.tram.literals.gia.emplacament.activitatAltr', callAux: true, userChoosen: true },
            					{ id: 'idEstablGia', type: 'text', order: 8, label: 'global.tram.literals.gia.emplacament.idEstGia', callAux: true, userChoosen: true},
            					{ id: 'idEstablGencat', type: 'text', order: 9, label: 'global.tram.literals.gia.emplacament.idEstGen', callAux: true, userChoosen: true },
            					{ id: 'tipusViaEmpl', type: 'text', order: 10, label: 'global.tram.literals.gia.address.tipusVia', callAux: true, userChoosen: true },
            					{ id: 'nomViaEmpl', type: 'text', order: 11, label: 'global.tram.literals.gia.address.nomVia', callAux: true, userChoosen: true},
            					{ id: 'numViaEmpl', type: 'text', order: 12, label: 'global.tram.literals.gia.address.num', callAux: true, userChoosen: true },
            					{ id: 'barriEmpl', type: 'text', order: 13, label: 'global.tram.literals.gia.address.barri', callAux: true, userChoosen: true },
            					{ id: 'exact', type: 'select', order: 14, label: 'global.tram.literals.gia.exact', dataType: 'LOCAL', data: vm.exacts, addAll: true, nameProperty: 'name', callAux: true, userChoosen: true},
            					{ id: 'estat', type: 'select', order: 15, label: 'global.tram.literals.gia.estat', dataType: 'LOCAL', data: vm.estats, addAll: true, nameProperty: 'name', callAux: true, userChoosen: true}
        					],
        					tableDefinition: {
        						id: 'tableAdvancedSearchActivity',
        						objectsPaginated:getActivity,
        						sortName: 'idAc',
        						sort: [[0, 'asc']],
        						columns: [
        							{ id: 'idAc', title: $filter('translate')('global.tram.literals.gia.activity'), sortable: false },
        							{ id: 'nomRs', title: $filter('translate')('global.tram.literals.gia.entitatRaoSocial'), sortable: false },
        							{ id: 'nomCom', title: $filter('translate')('global.tram.literals.gia.emplacament.nomCom'), sortable: false },
        							{ id: 'activitatPpal', title: $filter('translate')('global.tram.literals.gia.emplacament.activitatPpal'), sortable: false }
    							],
    							displayAll:true,
    							callAuxToAdd: { }
        					},
        					multiple: false
            		}
            		AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalGIAActivity', advancedSearchModal);
            	}else{
            		DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.activityNotExist'));
            	}
            }
            
            vm.emptyFunction = function(val, prop){
            }
            
            vm.removeActivity = function(){
            	DialogsFactory.confirm('global.tram.literals.gia.activities.REMOVE', 'global.tram.literals.gia.removeActivity').then(function (data) {
            		if(vm.activity && vm.activity.id && vm.dossierTransaction && vm.dossierTransaction.id){
            			vm.showActivity = false;
            			TramNewFactory.executeRemoveActivity(vm.dossierTransaction.id).then(function(data){
            				if(data.errorCodi === '00' || !data.errorCodi){
            					vm.activity.executed = true;
            					vm.activityAux.executed = true;
            					DialogsFactory.notify($filter('translate')('global.tram.literals.gia.executedOk'));
            				}else{
            					var error = ((data.errorCodi)?data.errorCodi+((data.errorDescripcio)?" - ":''):'');
            					error = error + ((data.errorDescripcio)?data.errorDescripcio:'');
            					if(!error || error === ''){
            						error = $filter('translate')('global.tram.literals.gia.errors.updateError');
            					}
            					DialogsFactory.error(error);	
            				}
            				vm.showActivity = true;
                		}).catch(function(error){
                			if(error && error.message && error.message.toUpperCase().includes("required".toUpperCase())){
                				DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.removeRequired'));
                			}else{
                				DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.updateError'));	
                			}
                			vm.showActivity = true;
    					});
            		}else{
                		DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.activityNotExist'));
                	}
               }).catch(function (data) {
    	               //Empty
    	       });
            }
			this.$onInit = function () {
				vm.languageColumn = Language.getActiveColumn();
				if(vm.dossierTransaction && vm.dossierTransaction.id){
					TramNewFactory.getRemoveActivity(vm.dossierTransaction.id).then(function(data){
						vm.activity = JSOG.decode(data);
						vm.activityAux = angular.copy(vm.activity);
						vm.showActivity = true;
					}).catch(function(error){
						vm.showActivity = false;			
					});
				}else{
					vm.showActivity = false;
				}
			}
			
        }],
        bindings: {
            dossierTransaction: '=',
            canUpdate: '='
        }
    })