/**
 * Created by apop on 06/09/2018.
 */
angular
    .module('annexaApp')
    .component('annexaGiaStartActivity', {
        templateUrl: './components/tram/annexa-gia/annexa-gia-start-activity.html',
        controller: ['Language', 'TramNewFactory','$rootScope', 'GlobalDataFactory', '$filter', 'globalModals', 'AnnexaFormlyFactory', 'DialogsFactory', 'AnnexaPermissionsFactory', 'apiAdmin', 'RestService', '$scope', 'HelperService', 'TerritoryFactory', function (Language, TramNewFactory, $rootScope, GlobalDataFactory, $filter, globalModals, AnnexaFormlyFactory, DialogsFactory, AnnexaPermissionsFactory, apiAdmin, RestService, $scope, HelperService, TerritoryFactory) {
            var vm = this;
            vm.languageColumn = "language1";
            vm.activity = {};
            vm.activityAux = {};
            vm.showActivity = false;
            vm.modifyReg = false;            
            vm.updateActivity = function(val, prop){
            	if(vm.activity && vm.activity.id && vm.dossierTransaction && vm.dossierTransaction.id){
            		vm.activity[prop] = val;
            		TramNewFactory.updateStartActivityGia(vm.dossierTransaction.id, vm.activity).then(function(data){
            			vm.activityAux[prop] = vm.activity[prop];
            		}).catch(function(error){
           				vm.activity[prop] = vm.activityAux[prop];
            			DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.updateError'));	
					});
            	}else{
            		DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.activityNotExist'));
            	}
			}
            
            vm.emptyFunction = function(val, prop){
            }
            
            vm.printEspaiExt = function (model) {
             	if(model) {
                    return $filter('translate')('global.literals.yes');
                } else {
                    return $filter('translate')('global.literals.no');
                }
            }
            
            vm.printPiscinaPub = function (model) {
             	if(model) {
                     return $filter('translate')('global.literals.yes');
                 } else {
                     return $filter('translate')('global.literals.no');
                 }
            }
            
            var submitModalClassification = function(selected, modal){
              if(selected && selected.length > 0 && vm.activity && vm.activity.id && vm.dossierTransaction && vm.dossierTransaction.id){
            	  if(selected[0].id){
            		vm.activity.classifId = selected[0].classifId; 
            		vm.activity.classifAnnex = selected[0].classifAnnex;
            		vm.activity.classifApartat = selected[0].classifApartat;
            		vm.activity.classifDescripcio = selected[0].classifDescripcio;
            		vm.activity.classif = ((vm.activity.classifAnnex)?vm.activity.classifAnnex + ' - ':'') + ((vm.activity.classifApartat)?vm.activity.classifApartat + ' - ':'') + vm.activity.classifDescripcio;
            		TramNewFactory.updateStartActivityGia(vm.dossierTransaction.id, vm.activity).then(function(data){
              			vm.activityAux.classifId = vm.activity.classifId; 
              			vm.activityAux.classifAnnex = vm.activity.classifAnnex;
              			vm.activityAux.classifApartat = vm.activity.classifApartat;
              			vm.activityAux.classifDescripcio = vm.activity.classifDescripcio;
              			vm.activityAux.classif = ((vm.activity.classifAnnex)?vm.activity.classifAnnex + ' - ':'') + ((vm.activity.classifApartat)?vm.activity.classifApartat + ' - ':'') + vm.activity.classifDescripcio;
              			modal.close();
              		}).catch(function(error){
              			vm.activity.classifId = vm.activityAux.classifId; 
              			vm.activity.classifAnnex = vm.activityAux.classifAnnex;
              			vm.activity.classifApartat = vm.activityAux.classifApartat;
              			vm.activity.classifDescripcio = vm.activityAux.classifDescripcio;
              			vm.activity.classif = ((vm.activityAux.classifAnnex)?vm.activityAux.classifAnnex + ' - ':'') + ((vm.activityAux.classifApartat)?vm.activityAux.classifApartat + ' - ':'') + vm.activityAux.classifDescripcio;
              			DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.updateError'));	
  					});         	
           	   	  }
              }
            }
            
            var submitModalRegister = function(selected, modal){
            	if(selected && selected.length > 0 && vm.activity && vm.activity.id && vm.dossierTransaction && vm.dossierTransaction.id){
              	  if(selected[0].registerEntry){
              		vm.activity.numRe = selected[0].registerEntry.entryNumber; 
              		vm.activity.dataRe = selected[0].registerEntry.registerDate;
              		vm.modifyReg = true;
              		TramNewFactory.updateStartActivityGia(vm.dossierTransaction.id, vm.activity).then(function(data){
                			vm.activityAux.numRe = vm.activity.numRe; 
                			vm.activityAux.dataRe = vm.activity.dataRe;
                			vm.modifyReg = false;
                			modal.close();
                		}).catch(function(error){
                			vm.activity.numRe = vm.activityAux.numRe; 
                			vm.activity.dataRe = vm.activityAux.dataRe;
                			vm.modifyReg = false;
                			DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.updateError'));	
    					});         	
             	   	  }
                }
            }

			var submitModalThird = function(selected, modal){
				if(selected && selected.length > 0 && vm.activity && vm.activity.id && vm.dossierTransaction && vm.dossierTransaction.id){
	              	  if(selected[0].id){
	              		  var modifyActivityThird = function(from, to){
	              			to.nomRs = from.nomRs; 
	              			to.nifRs = from.nifRs;
	              			to.munRs = from.munRs; 
	              			to.cpRs = from.cpRs;
	              			to.tipusViaRs = from.tipusViaRs; 
	              			to.nomViaRs = from.nomViaRs;
	              			to.numViaRs = from.numViaRs; 
	              			to.escRs = from.escRs; 
	              			to.pisRs = from.pisRs;
	              			to.portaRs = from.portaRs; 
	              			to.paisRs = from.paisRs;
	              			to.emailRs = from.emailRs;
	              			to.telRs = from.telRs; 
	              			to.mobRs = from.mobRs;
	              			to.nomRl = from.nomRl; 
	              			to.nifRl = from.nifRl;
	              			to.haveRl = from.haveRl;
	              			to.munRl = from.munRl; 
	              			to.cpRl = from.cpRl;
	              			to.tipusViaRl = from.tipusViaRl; 
	              			to.nomViaRl = from.nomViaRl;
	              			to.numViaRl = from.numViaRl; 
	              			to.escRl = from.escRl; 
	              			to.pisRl = from.pisRl;
	              			to.portaRl = from.portaRl; 
	              			to.paisRl = from.paisRl;
	              			to.munEmpl = from.munEmpl; 
	              			to.emailRl = from.emailRl;
	              			to.telRl = from.telRl; 
	              			to.mobRl = from.mobRl;
	              		  }
	              		  TramNewFactory.updateThirdActivity(vm.dossierTransaction.id, vm.activity.id, selected[0].id).then(function(data){
	              			  	modifyActivityThird(data, vm.activity);
	              				modifyActivityThird(data, vm.activityAux);
	                			modal.close();
	                		}).catch(function(error){
	                			modifyActivityThird(vm.activityAux, vm.activity);
	                			DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.updateError'));	
	    					});         	
	             	   	  }
	                }
			}
			
			var submitModalAddress = function(selected, modal){
				if(selected && selected.length > 0 && vm.activity && vm.activity.id && vm.dossierTransaction && vm.dossierTransaction.id){
              	  if(selected[0].address && selected[0].address.id){
              		  var modifyActivityAddress = function(from, to){
              			to.munEmpl = from.munEmpl; 
              			to.refCadastral = from.refCadastral;
              			to.coordX = from.coordX; 
              			to.coordY = from.coordY;
              			to.cpEmpl = from.cpEmpl; 
              			to.tipusViaEmpl = from.tipusViaEmpl; 
              			to.nomViaEmpl = from.nomViaEmpl;
              			to.numViaEmpl = from.numViaEmpl; 
              			to.escEmpl = from.escEmpl; 
              			to.pisEmpl = from.pisEmpl;
              			to.portaEmpl = from.portaEmpl; 
              			to.paisEmpl = from.paisEmpl;
              		  }
              		  TramNewFactory.updateLocationAddressGia(vm.dossierTransaction.id, vm.activity.id, selected[0].address.id).then(function(data){
              			  	modifyActivityAddress(data, vm.activity);
              			  	modifyActivityAddress(data, vm.activityAux);
                			modal.close();
                		}).catch(function(error){
                			modifyActivityAddress(vm.activityAux, vm.activity);
                			DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.updateError'));	
    					});         	
             	   	  }
                }
			}
            
			var submitModalActivity = function(selected, modal){
				if(selected && selected.length > 0 && vm.activity && vm.activity.id && vm.dossierTransaction && vm.dossierTransaction.id){
					if(selected[0].id){
						vm.activity.idEstablGia = selected[0].idEstablGia; 
						vm.activity.idEstablGencat = selected[0].idEstablGencat;
						TramNewFactory.updateStartActivityGia(vm.dossierTransaction.id, vm.activity).then(function(data){
							vm.activityAux.idEstablGia = vm.activity.idEstablGia; 
							vm.activityAux.idEstablGencat = vm.activity.idEstablGencat;
							modal.close();
						}).catch(function(error){
							vm.activity.idEstablGia = vm.activityAux.idEstablGia; 
							vm.activity.idEstablGencat = vm.activityAux.idEstablGencat;
							DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.updateError'));	
						});         	
					}
				}
			}

			$scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
            	if(args && args.id){
	                if(args.id === 'modalGIAClassification') {
	                	submitModalClassification(args.selected, args.modal);
	                }else if(args.id === 'modalGIAGetRegister') {
	                	submitModalRegister(args.selected, args.modal);
	                }else if(args.id === 'modalGIAGetThird') {
	                	submitModalThird(args.selected, args.modal);
	                }else if(args.id === 'modalGIAGetAddress') {
	                	submitModalAddress(args.selected, args.modal);
	                }else if(args.id === 'modalGIAActivity') {
  	                	submitModalActivity(args.selected, args.modal);
  	                }
            	}
            });
            
              
            vm.openModalGetClassification = function(){
            	if(vm.dossierTransaction && vm.dossierTransaction.id){
            		var getClassification = function(pag, size){
            			var call = {}
            			if(this.filterCallAux){
            				if(this.filterCallAux.classifAnnex){
            					call.classifAnnex = this.filterCallAux.classifAnnex
            				}
            				if(this.filterCallAux.classifApartat){
            					call.classifApartat = this.filterCallAux.classifApartat
            				}
            				if(this.filterCallAux.classifDescripcio){
            					call.classifDescripcio = this.filterCallAux.classifDescripcio
            				}
            			}
            			return TramNewFactory.getClassifications(vm.dossierTransaction.id, call, pag, size);
            		}
            		var advancedSearchModal = {
            				title: 'global.tram.literals.gia.emplacament.classif',
            				size: 'modal-lg',
            				filter: [
            					{ id: 'classifAnnex', type: 'text', order: 0, label: 'global.tram.literals.gia.classifAnnex', callAux: true },
            					{ id: 'classifApartat', type: 'text', order: 1, label: 'global.tram.literals.gia.classifApartat', callAux: true },
            					{ id: 'classifDescripcio', type: 'text', order: 2, label: 'global.tram.literals.gia.classifDescripcio', callAux: true}
        					],
        					tableDefinition: {
        						id: 'tableAdvancedSearchClassifications',
        						objectsPaginated:getClassification,
        						sortName: 'classifId',
        						sort: [[0, 'asc']],
        						columns: [
        							{ id: 'classifAnnex', title: $filter('translate')('global.tram.literals.gia.classifAnnex'), sortable: false },
        							{ id: 'classifApartat', title: $filter('translate')('global.tram.literals.gia.classifApartat'), sortable: false },
        							{ id: 'classifDescripcio', title: $filter('translate')('global.tram.literals.gia.classifDescripcio'), sortable: false }
    							],
    							displayAll:true,
    							callAuxToAdd: { }
        					},
        					multiple: false
            		}
            		AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalGIAClassification', advancedSearchModal);
            	}else{
            		DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.activityNotExist'));
            	}
            }
            
            vm.openModalGetAddressEmpl = function(){
            	 	var streetTypes = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.streetTypes));
					var countries = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.countries));
	                var advancedSearchModal = {
                     title: 'global.literals.advancedSearchTerritory',
                     size: 'modal-lg',
                     filter: [
                    	 	{ id: 'address', type: 'text', order: 0, label: 'global.territory.list.address', callAux: true},
				    		{ id: 'addressType', type: 'select', order: 0, label: 'global.literals.addressType', dataType: 'LOCAL', data: GlobalDataFactory.addressTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, userChoosen: false },
							{ id: 'streetType', type: 'select', order:1, label:'global.thirds.literals.streetType', dataType: 'LOCAL', data:streetTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, userChoosen: false },
							{ id: 'street', type: 'text', order: 2, label: 'global.thirds.literals.streetName', callAux: true, userChoosen: false },
							{ id: 'numberAddress', type: 'text', order: 3, label: 'global.thirds.literals.number', callAux: true, userChoosen: false }, 
	        				{ id: 'country', type: 'select', order: 4, label: 'global.thirds.literals.country', dataType: 'LOCAL', data: countries, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, userChoosen: true },
							{ id: 'state', type: 'text', order: 5, label: 'global.thirds.literals.state', callAux: true, userChoosen: true }, 
	        				{ id: 'city', type: 'text', order: 6, label: 'global.literals.city', callAux: true, userChoosen: true }, 
	        				{ id: 'postalCode', type: 'text', order: 7, label: 'global.thirds.literals.postalCode', callAux: true, userChoosen: true }, 
	        				{ id: 'pseudoVia', type: 'text', order: 8, label: 'global.thirds.literals.pseudoVia', callAux: true, userChoosen: true }, 
	        				{ id: 'numberAddress2', type: 'text', order: 9, label: 'global.thirds.literals.number2', callAux: true, userChoosen: true }, 
	        				{ id: 'bis', type: 'text', order: 10, label: 'global.thirds.literals.bis', callAux: true, userChoosen: true }, 
	        				{ id: 'bis2', type: 'text', order: 11, label: 'global.thirds.literals.bis2', callAux: true, userChoosen: true }, 
	        				{ id: 'kilometer', type: 'text', order: 12, label: 'global.thirds.literals.kilometer', callAux: true, userChoosen: true }, 
	        				{ id: 'block', type: 'text', order: 13, label: 'global.thirds.literals.block', callAux: true, userChoosen: true }, 
	        				{ id: 'portal', type: 'text', order: 14, label: 'global.thirds.literals.portal', callAux: true, userChoosen: true }, 
	        				{ id: 'stair', type: 'text', order: 15, label: 'global.thirds.literals.stair', callAux: true, userChoosen: true }, 
	        				{ id: 'floor', type: 'text', order: 16, label: 'global.thirds.literals.floor', callAux: true, userChoosen: true }, 
	        				{ id: 'door', type: 'text', order: 17, label: 'global.thirds.literals.door', callAux: true, userChoosen: true }, 
	        				{ id: 'cadastralReference', type: 'text', order: 18, label: 'global.territory.list.cadastral_reference', callAux: true, userChoosen: true }, 
	        				{ id: 'coordinateX', type: 'text', order: 19, label: 'global.territory.list.coordinateX', callAux: true, userChoosen: true }, 
	        				{ id: 'coordinateY', type: 'text', order: 20, label: 'global.territory.list.coordinateY', callAux: true, userChoosen: true } 
                     ],
                     tableDefinition: {
                         id: 'tableAdvancedSearchAddress',
                         origin: 'tram',
                         objectType: 'DossierAddress',
                         sortName: 'address.address'+Language.getActiveColumn(),
                         sort: [[1, 'asc']],
                         columns: [
                             { id: 'address.address'+Language.getActiveColumn(), title: $filter('translate')('global.territory.list.address')}
                         ],
                         callAuxToAdd: {callFrom:"GIA"},
                         callToAdd: {dossier: {id:vm.dossierTransaction.dossierId}}
                     },
                     multiple: false
                 }
                 AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalGIAGetAddress', advancedSearchModal);
            }
            
            vm.openModalGetThird = function(){
            	var thirdTypeColumn = new IconFilterColumn($rootScope, 'filterData', 'thirdType', [
                    new IconSearchOption('all', '', 'fa-minus fa-stack-1x', 'fa-minus', 'global.literals.all'),
                    new IconSearchOption('PHISICAL','PHISICAL', 'fa-user fa-lg ico-third', 'fa-user fa-fw', 'global.thirds.literals.phisical'),
                    new IconSearchOption('LEGAL','LEGAL', 'fa-bank fa-lg ico-third', 'fa-bank fa-fw', 'global.thirds.literals.legal'),
                    new IconSearchOption('PERSONWITHOUTLEGALPERSONALITY','PERSONWITHOUTLEGALPERSONALITY', 'fa-puzzle-piece fa-lg ico-third', 'fa-puzzle-piece fa-fw', 'global.thirds.literals.personwithoutlegalpersonality')
                ], 'tableThirds');
            	
            	var streetTypes = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.streetTypes));
				var countries = HelperService.sortArrayByLanguage(angular.copy(TerritoryFactory.countries));
                var advancedSearchModal = {
                 title: 'global.literals.advanced_search_receivers',
                 size: 'modal-lg',
                 filter: [
                	{ id: 'identificationDocument', type: 'text', order: 0, label: 'global.literals.identifier', callAux: true},
 					{ id: 'name', type: 'text', order: 1, label: 'global.literals.name', callAux: true},
 					{ id: 'identificationReference', type: 'text', order: 2, label: 'global.thirds.literals.identificationReference', callAux: true},
 					{ id: 'city', type: 'text', order: 4, label: 'global.literals.city', callAux: true, userChoosen: true},
 					{ id: 'mail', type: 'text', order: 5, label: 'global.profile.email', callAux: true, userChoosen: true},
 					{ id: 'phone', type: 'text', order: 6, label: 'global.profile.phone', callAux: true, userChoosen: true},
 					{ id: 'identificationDocumentType', type: 'select', order: 7, label: 'global.thirds.literals.identificationdocumenttype', dataType: 'LOCAL', data: vm.thirdIdentificationDocumentTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, userChoosen: true},
 					{ id: 'thirdType', type: 'select', order: 8, label: 'global.literals.type', dataType: 'LOCAL', data: vm.thirdTypes, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, userChoosen: true},
 					{ id: 'dataThird', type: 'dateRange', order: 9, label: 'global.thirds.literals.dateCreated', callAux: true, userChoosen: true }
                 ],
                 tableDefinition: {
                     id: 'tableAdvancedSearchThird',
                     origin: 'tram',
                     objectType: 'DossierThird',
                     sortName: 'third.completeName',
                     sort: [[3, 'asc']],
                     columns: [
                    	 { id: 'third.thirdType', width: '150px', title: $filter('translate')('global.literals.type'), render:thirdTypeColumn.getRender},
                         { id: 'third.identificationDocument', width: '300px', title:$filter('translate')('global.literals.identifier') },
                         { id: 'third.completeName', width: '300px', title: $filter('translate')('global.thirds.literals.datatable.col2')}
                     ],
                     callAuxToAdd: {callFrom:"GIA"},
                     callToAdd: {dossier: {id:vm.dossierTransaction.dossierId}, interested:true}
                 },
                 multiple: false
             }
             AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalGIAGetThird', advancedSearchModal);
            }
            
            vm.openModalGetRegister = function(){
            	var renderEntryType = function(data, type, full, meta) {
            		var content = '';
                    if(data == 'INPUT') {
                        content += '<i class="fa fa-sign-in" title="{{ \'global.literals.input\' | translate }}"></i>';
                    } else {
                        content += '<i class="fa fa-sign-out" title="{{ \'global.literals.output\' | translate }}"></i></span>';
                    }
                    return content;
                }
                
                var advancedSearchModal = {
                    title: 'global.literals.advanced_search_registerEntry',
                    size: 'modal-lg',
                    filter: [
                        { id: 'entryNumber', type: 'text', order: 0, label: 'global.literals.number', callAux: true},
                        { id: 'extract', type: 'text', order: 1, label: 'global.literals.extract', callAux: true },
                        { id: 'dataReg', type: 'dateRange', order: 2, label: 'global.literals.regFrom', callAux: true, userChoosen: true },
                        { id: 'dataPres', type: 'dateRange', order: 3, label: 'global.literals.presFrom', callAux: true, userChoosen: true },
                        { id: 'entryDocumentType', type: 'select', order: 4, label: 'global.literals.regType', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryDocumentTypesInput, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, userChoosen: true },
                        { id: 'entryClassification', type: 'select', order: 5, label: 'global.literals.classification', dataType: 'LOCAL', data: GlobalDataFactory.registerEntryClassificationsInput, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, userChoosen: true },
                        { id: 'section', type: 'select-tree', order: 13, label: 'global.literals.section', dataType: 'LOCAL', data: GlobalDataFactory.sections, addAll: true, nameProperty: Language.getActiveColumn(), callAux: true, userChoosen: true }
                    ],
                    tableDefinition: {
                        id: 'tableAdvancedSearchDocuments',
                        origin: 'reg',
                        objectType: 'RegisterEntryDossier',
                        sortName: 'registerEntry.entryNumber',
                        sort: [[2, 'asc']],
                        columns: [
                            { id: 'registerEntry.entryType', width: '40px', title: $filter('translate')('global.literals.type'), render: renderEntryType },
                            { id: 'registerEntry.entryNumber', width: '100px', title: $filter('translate')('global.literals.number') },
                            { id: 'registerEntry.registerDate', width: '90px', column: new DateTimeColumn($filter,'global.reg.datatable.registerDate') },
                            { id: 'registerEntry.presentationDate', width: '90px', column: new DateColumn($filter,'global.literals.presFrom') },
                            { id: 'registerEntry.registerEntryChannel.'+ Language.getActiveColumn(), width: '50px', title: $filter('translate')('global.literals.channel') },
                            { id: 'registerEntry.extract', title: $filter('translate')('global.literals.extract'), sortable: false },
                            { id: 'registerEntry.registerEntryOffice.' + Language.getActiveColumn(), title: $filter('translate')('global.literals.office') }
                        ],
                        callAuxToAdd: {callFrom:"GIA"},
                        callToAdd: {dossier: {id:vm.dossierTransaction.dossierId}}
                    },
                    multiple: false
                }
                AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalGIAGetRegister', advancedSearchModal);
            }

            vm.openModalGetActivity = function(){
              	if(vm.dossierTransaction && vm.dossierTransaction.id){
              		var getActivity = function(pag, size){
              			var call = {}
              			if(this.filterCallAux){
              				if(this.filterCallAux.idAc){
              					call.idAc = this.filterCallAux.idAc
              				}
              				if(this.filterCallAux.refCadastral){
              					call.refCadastral = this.filterCallAux.refCadastral
              				}
              				if(this.filterCallAux.numExp){
              					call.numExp = this.filterCallAux.numExp
              				}
              				if(this.filterCallAux.nomRs){
              					call.nomRs = this.filterCallAux.nomRs
              				}
              				if(this.filterCallAux.nifRs){
              					call.nifRs = this.filterCallAux.nifRs
              				}
              				if(this.filterCallAux.nomCom){
              					call.nomCom = this.filterCallAux.nomCom
              				}
              				if(this.filterCallAux.activitatPpal){
              					call.activitatPpal = this.filterCallAux.activitatPpal
              				}
              				if(this.filterCallAux.activitatAltr){
              					call.activitatAltr = this.filterCallAux.activitatAltr
              				}
              				if(this.filterCallAux.idEstablGia){
              					call.idEstablGia = this.filterCallAux.idEstablGia
              				}
              				if(this.filterCallAux.idEstablGencat){
              					call.idEstablGencat = this.filterCallAux.idEstablGencat
              				}
              				if(this.filterCallAux.tipusViaEmpl){
              					call.tipusViaEmpl = this.filterCallAux.tipusViaEmpl
              				}
              				if(this.filterCallAux.nomViaEmpl){
              					call.nomViaEmpl = this.filterCallAux.nomViaEmpl
              				}
              				if(this.filterCallAux.numViaEmpl){
              					call.numViaEmpl = this.filterCallAux.numViaEmpl
              				}
              				if(this.filterCallAux.barriEmpl){
              					call.barriEmpl = this.filterCallAux.barriEmpl
              				}
              				if(this.filterCallAux.exact && this.filterCallAux.exact.id){
              					call.exact = this.filterCallAux.exact.id
              				}
              				if(this.filterCallAux.estat && this.filterCallAux.estat.id){
              					call.estat = this.filterCallAux.estat.id
              				}
              			}
              			return TramNewFactory.searchActivities(vm.dossierTransaction.id, call, pag, size);
              		}
              		var advancedSearchModal = {
              				title: 'global.tram.literals.gia.emplacament.classif',
              				size: 'modal-lg',
              				filter: [
              					{ id: 'idAc', type: 'text', order: 0, label: 'global.tram.literals.gia.activity', callAux: true },
              					{ id: 'refCadastral', type: 'text', order: 1, label: 'global.tram.literals.gia.emplacament.refCadastral', callAux: true },
              					{ id: 'numExp', type: 'text', order: 2, label: 'global.literals.dossierNumber', callAux: true},
              					{ id: 'nomRs', type: 'text', order: 3, label: 'global.tram.literals.gia.entitatRaoSocial', callAux: true },
              					{ id: 'nifRs', type: 'text', order: 4, label: 'global.tram.literals.gia.address.nif', callAux: true },
              					{ id: 'nomCom', type: 'text', order: 5, label: 'global.tram.literals.gia.emplacament.nomCom', callAux: true},
              					{ id: 'activitatPpal', type: 'text', order: 6, label: 'global.tram.literals.gia.emplacament.activitatPpal', callAux: true},
              					{ id: 'activitatAltr', type: 'text', order: 7, label: 'global.tram.literals.gia.emplacament.activitatAltr', callAux: true, userChoosen: true },
              					{ id: 'idEstablGia', type: 'text', order: 8, label: 'global.tram.literals.gia.emplacament.idEstGia', callAux: true, userChoosen: true},
              					{ id: 'idEstablGencat', type: 'text', order: 9, label: 'global.tram.literals.gia.emplacament.idEstGen', callAux: true, userChoosen: true },
              					{ id: 'tipusViaEmpl', type: 'text', order: 10, label: 'global.tram.literals.gia.address.tipusVia', callAux: true, userChoosen: true },
              					{ id: 'nomViaEmpl', type: 'text', order: 11, label: 'global.tram.literals.gia.address.nomVia', callAux: true, userChoosen: true},
              					{ id: 'numViaEmpl', type: 'text', order: 12, label: 'global.tram.literals.gia.address.num', callAux: true, userChoosen: true },
              					{ id: 'barriEmpl', type: 'text', order: 13, label: 'global.tram.literals.gia.address.barri', callAux: true, userChoosen: true },
              					{ id: 'exact', type: 'select', order: 14, label: 'global.tram.literals.gia.exact', dataType: 'LOCAL', data: vm.exacts, addAll: true, nameProperty: 'name', callAux: true, userChoosen: true},
              					{ id: 'estat', type: 'select', order: 15, label: 'global.tram.literals.gia.estat', dataType: 'LOCAL', data: vm.estats, addAll: true, nameProperty: 'name', callAux: true, userChoosen: true}
          					],
          					tableDefinition: {
          						id: 'tableAdvancedSearchActivity',
          						objectsPaginated:getActivity,
          						sortName: 'idAc',
          						sort: [[0, 'asc']],
          						columns: [
          							{ id: 'idAc', title: $filter('translate')('global.tram.literals.gia.activity'), sortable: false },
          							{ id: 'nomRs', title: $filter('translate')('global.tram.literals.gia.entitatRaoSocial'), sortable: false },
          							{ id: 'nomCom', title: $filter('translate')('global.tram.literals.gia.emplacament.nomCom'), sortable: false },
          							{ id: 'idEstablGia', title: $filter('translate')('global.tram.literals.gia.emplacament.idEstGia'), sortable: false },
          							{ id: 'idEstablGencat', title: $filter('translate')('global.tram.literals.gia.emplacament.idEstGen'), sortable: false }
      							],
      							displayAll:true,
      							callAuxToAdd: { }
          					},
          					multiple: false
              		}
              		AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalGIAActivity', advancedSearchModal);
              	}else{
              		DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.activityNotExist'));
              	}
              }
            
            vm.startActivity = function(){
            	DialogsFactory.confirm('global.tram.literals.gia.activities.START', 'global.tram.literals.gia.startActivity').then(function (data) {
            		if(vm.activity && vm.activity.id && vm.dossierTransaction && vm.dossierTransaction.id){
            			vm.showActivity = false;
            			TramNewFactory.executeStartActivityGia(vm.dossierTransaction.id).then(function(data){
            				if(data.idAc && (data.errorCodi === '00' || !data.errorCodi)){
            					vm.activity.idAc = data.idAc;
            					vm.activity.executed = true;
            					vm.activityAux.idAc = data.idAc;
            					vm.activityAux.executed = true;
            					DialogsFactory.notify($filter('translate')('global.tram.literals.gia.executedOk'));
            				}else{
            					var error = ((data.errorCodi)?data.errorCodi+((data.errorDescripcio)?" - ":''):'');
            					error = error + ((data.errorDescripcio)?data.errorDescripcio:'');
            					if(!error || error === ''){
            						error = $filter('translate')('global.tram.literals.gia.errors.updateError');
            					}
            					DialogsFactory.error(error);	
            				}
            				vm.showActivity = true;
                		}).catch(function(error){
                			if(error && error.message && error.message.toUpperCase().includes("required".toUpperCase())){
                				DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.startRequired'));
                			}else{
                				DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.updateError'));	
                			}
                			vm.showActivity = true;
    					});
            		}else{
                		DialogsFactory.error($filter('translate')('global.tram.literals.gia.errors.activityNotExist'));
                	}
               }).catch(function (data) {
    	               //Empty
    	       });
            }
			this.$onInit = function () {
				vm.languageColumn = Language.getActiveColumn();
				vm.modifyReg = false;
				if(vm.dossierTransaction && vm.dossierTransaction.id){
					TramNewFactory.getStartActivityGia(vm.dossierTransaction.id).then(function(data){
						vm.activity = JSOG.decode(data);
						if(vm.activity){
							vm.activity.classif = ((vm.activity.classifAnnex)?vm.activity.classifAnnex + ' - ':'') + ((vm.activity.classifApartat)?vm.activity.classifApartat + ' - ':'') + ((vm.activity.classifDescripcio)?vm.activity.classifDescripcio + ' ':'');
						}
						vm.activityAux = angular.copy(vm.activity);
						vm.showActivity = true;
					}).catch(function(error){
						vm.showActivity = false;			
					});
				}else{
					vm.showActivity = false;
				}
			}
			
        }],
        bindings: {
            dossierTransaction: '=',
            canUpdate: '='
        }
    })